import Clickable from '/components/Clickable';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { getButtonImageSrc } from '/services/products';
import { getSimilarPrints } from '/services/searchspring/';
import { getTestVariant } from '/services/ab-test';
import { useEffect, useState } from 'react';

const PrintSelector = ({ product, size }) => {
	const [printOptions, setPrintOptions] = useState([]);

	useEffect(() => {
		if (!product) {
			return;
		}

		async function fetchSimilarPrints() {
			const result = await getSimilarPrints(product, null, size);
			setPrintOptions(result);
		}

		fetchSimilarPrints();
		document.getElementById('scroll-section').scrollLeft -= 1000;
	}, [product, size]);

	return (
		<div className="mb-6">
			<div className="h-[70px] overflow-x-scroll overflow-y-hidden md:hidden" id="scroll-section">
				<div
					className="flex pt-1 pl-1  gap-2"
					style={{
						width: String(printOptions.length < 50 ? printOptions.length * 65 : 50 * 65) + 'px',
					}}>
					{printOptions.map((print, i) => {
						const buttonImage = getButtonImageSrc(print);
						if (!buttonImage) {
							return null;
						}
						return (
							<Clickable
								key={i}
								linkToInternal={'/products/' + print.handle}
								className={classNames(
									'rounded-full h-[50px] w-[50px] m-px',
									product.handle === print.handle
										? 'border-2 border-white ring-4 ring-v2-brown-darker rounded-full hover:ring-v2-brown-darker'
										: 'hover:ring-v2-brown hover:border-2 border-white hover:ring-4',
								)}>
								<Image
									src={buttonImage}
									height={200}
									width={200}
									objectFit="contain"
									alt="button image"
								/>
							</Clickable>
						);
					})}
				</div>
			</div>
			<div className="flex-wrap rounded-b-lg justify-left hidden md:flex">
				{printOptions.map((print, i) => {
					const buttonImage = getButtonImageSrc(print);
					if (!buttonImage) {
						return null;
					}
					return (
						<Clickable
							key={i}
							linkToInternal={'/products/' + print.handle}
							className={classNames(
								'overflow-hidden rounded-full m-1 h-12 w-12 hover:ring-v2-brown hover:border-2 border-white hover:ring-4',
								product.handle === print.handle
									? 'border-2 border-white ring-4 ring-v2-brown-darker rounded-full hover:ring-v2-brown-darker '
									: '',
							)}>
							<Image src={buttonImage} height={200} width={200} alt="button image" />
						</Clickable>
					);
				})}
			</div>
		</div>
	);
};

const PrintSelectorBuckets = ({ product, setActiveProductHandle, size }) => {
	const [activeFlavor, setActiveFlavor] = useState();
	const [printOptions, setPrintOptions] = useState([]);
	const [testVariant, setTestVariant] = useState(null);

	useEffect(() => {
		async function fetchTestVariant() {
			const variant = await getTestVariant('print-options-groups');
			setTestVariant(variant.key);
		}
		fetchTestVariant();
	}, []);

	useEffect(() => {
		const print = product?.tags?.find((element) => {
			return element.includes('print:');
		});

		setActiveFlavor(print);
	}, [product]);

	useEffect(() => {
		if (!product || !activeFlavor) {
			return;
		}

		async function fetchSimilarPrints() {
			const result = await getSimilarPrints(product, activeFlavor, size);

			const index = result.findIndex((item) => item.handle === product.handle);

			if (index && index > 14) {
				const currentProduct = result[index];
				result.splice(index, 1);
				result.unshift({
					auxiliaryImages: {
						buttonSrc: currentProduct?.buttonImage,
					},
					image: currentProduct.imageSrc,
					handle: currentProduct.handle,
				});
			}

			setPrintOptions(result);
		}
		if (testVariant === 'control') {
			document.getElementById('scroll-section').scrollLeft -= 1000;
		}
		fetchSimilarPrints();
	}, [activeFlavor, product, size]);

	if (testVariant === 'print-breakout') {
		return (
			<div className="mb-6">
				<PrintSelectorCategories
					product={product}
					setActiveProductHandle={setActiveProductHandle}
					size={size}
				/>
			</div>
		);
	}

	// Original version (control)
	return (
		<div className="mb-6">
			<div className="flex justify-left mb-4 w-full gap-2">
				<Clickable
					className={classNames(
						'h-[46px] capitalize font-bold tracking-wider w-1/3 text-v2-brown-darker',
						activeFlavor === 'print: Solids'
							? 'border-2 border-v2-brown-darker rounded-full'
							: 'hover:rounded-full hover:border-2 hover:border-gray-500',
					)}
					onClick={() => setActiveFlavor('print: Solids')}>
					<Typography component="p" variant="body">
						Solids
					</Typography>
				</Clickable>
				<Clickable
					className={classNames(
						'h-[46px] capitalize font-bold tracking-wider  w-1/3 text-v2-brown-darker',
						activeFlavor === 'print: Patterned'
							? 'v2-brown-darker border-2 border-v2-brown-darker rounded-full'
							: 'hover:rounded-full hover:border-2 hover:border-gray-500 t',
					)}
					onClick={() => setActiveFlavor('print: Patterned')}>
					<Typography component="p" variant="body">
						Patterns
					</Typography>
				</Clickable>
				{!product?.productType?.includes('Socks') && !product?.productType?.includes('Bralette') && (
					<Clickable
						className={classNames(
							'h-[46px] capitalize font-bold tracking-wider  w-1/3 text-v2-brown-darker',
							activeFlavor === 'print: Apex'
								? ' v2-brown-darker border-2 border-v2-brown-darker rounded-full'
								: 'hover:rounded-full hover:border-2 hover:border-gray-500',
						)}
						onClick={() => setActiveFlavor('print: Apex')}>
						<Typography component="p" variant="body">
							Crotch shots
						</Typography>
					</Clickable>
				)}
			</div>

			<div className="h-[70px] overflow-x-scroll overflow-y-hidden md:hidden" id="scroll-section">
				<div
					className="flex pt-1 pl-1  gap-2"
					style={{
						width: String(printOptions.length < 50 ? printOptions.length * 65 : 50 * 65) + 'px',
					}}>
					{printOptions.map((print, i) => {
						const buttonImage = getButtonImageSrc(print);
						if (buttonImage && i < 50) {
							if (product.setActiveProductHandle) {
								return (
									<Clickable
										key={i}
										onClick={() => setActiveProductHandle(print.handle)}
										className={classNames(
											'rounded-full h-[50px] w-[50px] m-px',
											product.handle === print.handle
												? 'border-2 border-white ring-4 ring-v2-brown-darker rounded-full hover:ring-v2-brown-darker'
												: '',
										)}>
										<Image
											src={buttonImage}
											height={200}
											width={200}
											objectFit="contain"
											alt="button image"
										/>
									</Clickable>
								);
							}
							return (
								<Clickable
									key={i}
									linkToInternal={'/products/' + print.handle}
									className={classNames(
										'rounded-full h-[50px] w-[50px] m-1 relative',
										product.handle === print.handle
											? 'border-2 border-white ring-4 ring-v2-brown-darker rounded-full hover:ring-v2-brown-darker'
											: '',
									)}>
									<Image
										src={buttonImage}
										height={200}
										width={200}
										objectFit="contain"
										alt="button image"
									/>
								</Clickable>
							);
						}
					})}
				</div>
			</div>

			<div className="flex-wrap rounded-b-lg justify-left hidden md:flex">
				{printOptions.map((print, i) => {
					const buttonImage = getButtonImageSrc(print);

					if (buttonImage && i < 50) {
						if (setActiveProductHandle) {
							return (
								<Clickable
									key={i}
									onClick={() => setActiveProductHandle(print.handle)}
									className={classNames(
										'rounded-full h-[50px] w-[50px] m-1',
										product.handle === print.handle
											? 'border-2 border-white ring-4 ring-v2-brown-darker rounded-full hover:ring-v2-brown-darker'
											: '',
									)}>
									<Image
										src={buttonImage}
										height={200}
										width={200}
										objectFit="contain"
										alt="button image"
									/>
								</Clickable>
							);
						}
						return (
							<Clickable
								key={i}
								linkToInternal={'/products/' + print.handle}
								className={classNames(
									'overflow-hidden rounded-full m-1 h-12 w-12 hover:ring-v2-brown hover:border-2 border-white hover:ring-4',
									product.handle === print.handle
										? 'border-2 border-white ring-4 ring-v2-brown-darker rounded-full hover:ring-v2-brown-darker '
										: '',
								)}>
								<Image src={buttonImage} height={200} width={200} alt="button image" />
							</Clickable>
						);
					}
				})}
			</div>
		</div>
	);
};

const PrintSelectorCategories = ({ product, setActiveProductHandle, size }) => {
	const [printOptions, setPrintOptions] = useState({
		solids: [],
		patterns: [],
		apex: [],
	});
	const [expandedCategories, setExpandedCategories] = useState({
		solids: false,
		patterns: false,
		apex: false,
	});

	useEffect(() => {
		if (!product) {
			return;
		}

		async function fetchCategoryPrints() {
			const solidsResult = await getSimilarPrints(product, 'print: Solids', size);
			const patternsResult = await getSimilarPrints(product, 'print: Patterned', size);

			let apexResult = [];
			if (!product?.productType?.includes('Socks') && !product?.productType?.includes('Bralette')) {
				apexResult = await getSimilarPrints(product, 'print: Apex', size);
			}

			setPrintOptions({
				solids: solidsResult,
				patterns: patternsResult,
				apex: apexResult,
			});
		}

		fetchCategoryPrints();
	}, [product, size]);

	const toggleExpand = (category) => {
		setExpandedCategories((prev) => ({
			...prev,
			[category]: !prev[category],
		}));
	};

	return (
		<div className="mb-6">
			<PrintCategory
				categoryName="solids"
				displayName="Solids"
				prints={printOptions.solids}
				product={product}
				setActiveProductHandle={setActiveProductHandle}
				isExpanded={expandedCategories.solids}
				toggleExpand={toggleExpand}
			/>
			<PrintCategory
				categoryName="patterns"
				displayName="Patterns"
				prints={printOptions.patterns}
				product={product}
				setActiveProductHandle={setActiveProductHandle}
				isExpanded={expandedCategories.patterns}
				toggleExpand={toggleExpand}
			/>
			{!product?.productType?.includes('Socks') && !product?.productType?.includes('Bralette') && (
				<PrintCategory
					categoryName="apex"
					displayName="Crotch Shots"
					prints={printOptions.apex}
					product={product}
					setActiveProductHandle={setActiveProductHandle}
					isExpanded={expandedCategories.apex}
					toggleExpand={toggleExpand}
				/>
			)}
		</div>
	);
};

const PrintCategory = ({
	categoryName,
	displayName,
	prints,
	product,
	setActiveProductHandle,
	isExpanded,
	toggleExpand,
}) => {
	const initialDisplayCount = 5;
	const displayPrints = isExpanded ? prints : prints.slice(0, initialDisplayCount);
	const hasMoreToShow = prints.length > initialDisplayCount;

	return (
		<div className="mt-2 md:mt-4">
			<Typography component="h3" variant="h3" className="text-v2-brown-darker font-bold">
				{displayName}
			</Typography>

			<div
				className="h-[65px] overflow-x-scroll overflow-y-hidden md:hidden"
				id={`scroll-section-${categoryName}`}>
				<div
					className="flex pt-1 pl-1 gap-2 relative"
					style={{
						width: String(displayPrints.length < 50 ? displayPrints.length * 70 : 50 * 65) + 'px',
					}}>
					{displayPrints.map((print, i) => {
						const buttonImage = getButtonImageSrc(print);
						if (buttonImage && i < 50) {
							if (setActiveProductHandle) {
								return (
									<Clickable
										key={i}
										onClick={() => setActiveProductHandle(print.handle)}
										className={classNames(
											'rounded-full h-[50px] w-[50px] m-px flex items-center justify-center',
											product.handle === print.handle
												? 'border-2 border-white ring-4 ring-v2-brown-darker rounded-full hover:ring-v2-brown-darker'
												: 'hover:ring-v2-brown hover:border-2 border-white hover:ring-4',
										)}>
										<Image
											src={buttonImage}
											height={50}
											width={50}
											objectFit="contain"
											alt="button image"
											className="rounded-full"
										/>
									</Clickable>
								);
							}
							return (
								<Clickable
									key={i}
									linkToInternal={'/products/' + print.handle}
									className={classNames(
										'rounded-full h-[50px] w-[50px] m-1 relative flex items-center justify-center',
										product.handle === print.handle
											? 'border-2 border-white ring-4 ring-v2-brown-darker rounded-full hover:ring-v2-brown-darker'
											: 'hover:ring-v2-brown hover:border-2 border-white hover:ring-4',
									)}>
									<Image
										src={buttonImage}
										height={50}
										width={50}
										objectFit="contain"
										alt="button image"
										className="rounded-full"
									/>
								</Clickable>
							);
						}
						return null;
					})}

					{!isExpanded && hasMoreToShow && (
						<Clickable
							onClick={() => toggleExpand(categoryName)}
							className="flex items-center justify-center h-[50px]">
							<Typography component="span" className="text-v2-brown-darker font-bold text-md">
								+{prints.length - initialDisplayCount}
							</Typography>
						</Clickable>
					)}
				</div>
			</div>

			{/* Desktop section */}
			<div className="flex-wrap rounded-b-lg justify-left hidden md:flex relative">
				{displayPrints.map((print, i) => {
					const buttonImage = getButtonImageSrc(print);
					if (buttonImage && i < 50) {
						if (setActiveProductHandle) {
							return (
								<Clickable
									key={i}
									onClick={() => setActiveProductHandle(print.handle)}
									className={classNames(
										'rounded-full h-[50px] w-[50px] m-1 flex items-center justify-center',
										product.handle === print.handle
											? 'border-2 border-white ring-4 ring-v2-brown-darker rounded-full hover:ring-v2-brown-darker'
											: 'hover:ring-v2-brown hover:border-2 border-white hover:ring-4',
									)}>
									<Image
										src={buttonImage}
										height={50}
										width={50}
										objectFit="contain"
										alt="button image"
										className="rounded-full"
									/>
								</Clickable>
							);
						}
						return (
							<Clickable
								key={i}
								linkToInternal={'/products/' + print.handle}
								className={classNames(
									'rounded-full h-[50px] w-[50px] m-1 flex items-center justify-center',
									product.handle === print.handle
										? 'border-2 border-white ring-4 ring-v2-brown-darker rounded-full hover:ring-v2-brown-darker'
										: 'hover:ring-v2-brown hover:border-2 border-white hover:ring-4',
								)}>
								<Image
									src={buttonImage}
									height={50}
									width={50}
									objectFit="contain"
									alt="button image"
									className="rounded-full"
								/>
							</Clickable>
						);
					}
					return null;
				})}

				{/* +10 button for desktop */}
				{!isExpanded && hasMoreToShow && (
					<Clickable
						onClick={() => toggleExpand(categoryName)}
						className="flex items-center justify-center h-[50px] ml-2">
						<Typography component="span" className="text-v2-brown-darker font-bold text-lg">
							+{prints.length - initialDisplayCount}
						</Typography>
					</Clickable>
				)}
			</div>
		</div>
	);
};

module.exports = {
	PrintSelector,
	PrintSelectorBuckets,
	PrintSelectorCategories,
};

PrintSelector.propTypes = {
	product: PropTypes.object,
	size: PropTypes.string,
};

PrintSelectorBuckets.propTypes = {
	product: PropTypes.object,
	setActiveProductHandle: PropTypes.func,
	size: PropTypes.string,
};

PrintSelectorCategories.propTypes = {
	product: PropTypes.object,
	setActiveProductHandle: PropTypes.func,
	size: PropTypes.string,
};

PrintCategory.propTypes = {
	categoryName: PropTypes.string.isRequired,
	displayName: PropTypes.string.isRequired,
	isExpanded: PropTypes.bool.isRequired,
	prints: PropTypes.array.isRequired,
	product: PropTypes.object,
	setActiveProductHandle: PropTypes.func,
	toggleExpand: PropTypes.func.isRequired,
};
