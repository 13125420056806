import axios from 'axios';
import { formatProduct } from '../utils/product-normalizers/subscription-platform';
import { getProductById } from '/services/products';
import { logTryCatch } from '/utils/logging';
import { parseGid } from '/utils/graph-ql';

const handleCutResponse = (response) => {
	const cuts = response?.products;
	return cuts.filter((cut) => {
		return cut.shopifyStatus == 'active';
	});
};

const getProductTypeAssortmentsShine = (body) => {
	return axios
		.post(`${process.env.NEXT_PUBLIC_SHINE_API}/public/assortments-by-product-types`, body)
		.then((res) => {
			if (res?.data) {
				return res.data.map((product) => {
					return formatProduct(product);
				});
			}

			return [];
		})
		.catch((err) => {
			logTryCatch(err);
		});
};

const getCutsFromProductId = (productId) => {
	if (!productId || productId === '') {
		return;
	}

	return axios
		.get(
			`${process.env.NEXT_PUBLIC_SHINE_API}/public/assortment-by-product-id/` + parseGid(productId),
		)
		.then((res) => {
			return handleCutResponse(res.data);
		})
		.catch((err) => {
			logTryCatch(err);
		});
};

const getAvailableCutsFromAssortment = (assortmentId) => {
	return axios
		.get(`${process.env.NEXT_PUBLIC_SHINESTY_SUBSCRIPTION_API}/assortment/` + assortmentId)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			logTryCatch(err);
		});
};

const getProductsInAssortment = async (assortmentId) => {
	if (!assortmentId) {
		return;
	}

	const assortmentResponse = await getAvailableCutsFromAssortment(assortmentId);

	if (!assortmentResponse || !assortmentResponse.products) {
		return;
	}

	const promises = assortmentResponse.products.reduce((memo, product) => {
		if (!product.shopifyProductId) {
			return memo;
		}

		memo.push(getProductById(product.shopifyProductId));
		return memo;
	}, []);

	const products = await Promise.all(promises);

	return products;
};

module.exports = {
	getAvailableCutsFromAssortment,
	getProductTypeAssortmentsShine,
	getProductsInAssortment,
	getCutsFromProductId,
};
