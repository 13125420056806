import axios from 'axios';
const sectionTypes = {
	CollectionCarousel: 'collection-carousel',
	FlyersFeature: 'flyers-feature',
	LinkList: 'link-list',
	Markdown: 'markdown',
	CategoryPreview: 'category-preview',
	CategoryPreviewGrid: 'category-preview-grid',
	TechnicalFeatures: 'technical-features',
	Hero: 'hero',
	ProductPage: 'product-single',
	CategoryTiles: 'category-preview-grid',
};

const getFormattedSectionType = (sectionType) => {
	return sectionTypes[sectionType] || sectionType;
};

const formatLandingPage = (landingPage) => {
	landingPage.sections.forEach((section) => {
		section.sectionType = getFormattedSectionType(section.sectionType || section.type);
		Object.assign(section, section.config || section.props);
	});
	return landingPage;
};

const getAllForPageType = (pageType) => {
	return axios({
		method: 'get',
		url: `${process.env.NEXT_PUBLIC_SHINE_PUBLIC}/landing-page`,
		params: { pageType, isIndexed: true, method: 'all' },
	})
		.then(handleSuccessPaginated)
		.catch(handleError);
};

const getDefaultHomepage = () => {
	return axios({
		method: 'get',
		url: `${process.env.NEXT_PUBLIC_SHINE_PUBLIC}/landing-page`,
		params: { pageType: 'home', limit: 1 },
	})
		.then((response) => formatLandingPage(response?.data))
		.catch(handleError);
};
const getHomePage = (clientId) => {
	return axios({
		method: 'get',
		url: `${process.env.NEXT_PUBLIC_SHINE_PUBLIC}/landing-page`,
		params: { clientId, method: 'home' },
	})
		.then((response) => formatLandingPage(response?.data))
		.catch(handleError);
};

const getLandingPageByHandle = (handle, pageType) => {
	if (!handle || handle === '') {
		return {};
	}

	return axios({
		method: 'get',
		url: `${process.env.NEXT_PUBLIC_SHINE_PUBLIC}/landing-page`,
		params: { pageType, method: 'content', handle },
	})
		.then((response) => formatLandingPage(response?.data))
		.catch(handleError);
};

const getLandingPagePreview = (id) => {
	if (!id || id === '') {
		return {};
	}

	return axios({
		method: 'get',
		url: `${process.env.NEXT_PUBLIC_SHINE_PUBLIC}/landing-page`,
		params: { id, method: 'single' },
	})
		.then((response) => formatLandingPage(response?.data))
		.catch(handleError);
};

const getProductDetails = (styleNumber) => {
	if (!styleNumber || styleNumber === '') {
		return {};
	}

	return axios({
		method: 'get',
		url: `${process.env.NEXT_PUBLIC_SHINE_PUBLIC}/product-details-by-style-number`,
		params: { styleNumber },
	})
		.then((response) => response?.data)
		.catch(handleError);
};

const getSubscriptionUnavailable = () => {
	return axios({
		method: 'get',
		url: `${process.env.NEXT_PUBLIC_SHINE_PLATFORM_PUBLIC_API}/landing-page?method=subscription-unavailable`,
	})
		.then((response) => response?.data)
		.catch(handleError);
};

const handleError = (response) => {
	return {
		code: response?.code,
		error: true,
	};
};

const handleSuccessPaginated = (response) => {
	return {
		results: response?.data || [],
		totalCount: response?.headers['x-total-count'],
	};
};

const searchLive = (params) => {
	return axios({
		method: 'get',
		params: { ...params, method: 'live' },
		url: `${process.env.NEXT_PUBLIC_SHINE_PUBLIC}/landing-page`,
	})
		.then(handleSuccessPaginated)
		.catch(handleError);
};

module.exports = {
	getAllForPageType,
	getDefaultHomepage,
	getHomePage,
	getLandingPageByHandle,
	getLandingPagePreview,
	getProductDetails,
	getSubscriptionUnavailable,
	searchLive,
};
