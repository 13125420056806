import CheckboxGroup from '/components/CheckBoxGroup';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { parseGid } from '/utils/graph-ql';
import { useState } from 'react';

const giftProducts = {
	7358675746885: {
		show: true,
		date: false,
		copy: {
			bullets: [
				'Select gift option when adding to cart',
				"Give us your friend's email address",
				'Enter their shipping address at checkout',
			],
			checkbox: "I'm sending this as a prank",
		},
	},
	7358675714117: {
		show: true,
		date: false,
		copy: {
			bullets: [
				'Select gift option when adding to cart',
				"Give us your friend's email address",
				'Enter their shipping address at checkout',
			],
			checkbox: "I'm sending this as a prank",
		},
	},
	190780105: {
		show: true,
		date: true,
		copy: {
			checkbox: "I'm sending this as a gift",
		},
	},
};

const GiftOptions = ({ product, onGiftOptionsChange = () => {} }) => {
	const [isGift, setIsGift] = useState(false);
	const [giftOptions, setGiftOptions] = useState({
		recipientName: '',
		recipientEmail: '',
		giftNote: '',
		deliveryDate: '',
	});
	const [showGiftForm, setShowGiftForm] = useState(false);
	const productId = parseGid(product.id);

	// useEffect(() => {
	// 	const timer = setTimeout(() => {
	// 		setIsGift(true);
	// 		setShowGiftForm(true);
	// 	}, 100);

	// 	return () => clearTimeout(timer);
	// }, []);

	if (!productId || !giftProducts[productId]) {
		return null;
	}
	const giftConfig = giftProducts[productId];

	const handleCheckboxChange = (checked) => {
		setIsGift(checked);
		setShowGiftForm(checked);

		if (!checked) {
			setGiftOptions({
				recipientName: '',
				recipientEmail: '',
				giftNote: '',
				deliveryDate: '',
			});
			onGiftOptionsChange(null);
		}
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		const updatedOptions = {
			...giftOptions,
			[name]: value,
		};

		setGiftOptions(updatedOptions);

		if (isGift) {
			const attributes = [
				{ key: 'Recipient Name', value: updatedOptions.recipientName },
				{ key: 'Recipient Email', value: updatedOptions.recipientEmail },
				{ key: 'Gift Note', value: updatedOptions.giftNote },
				{ key: 'Delivery Date', value: updatedOptions.deliveryDate },
			].filter((attr) => attr.value); // Only include attributes with values

			onGiftOptionsChange(attributes.length > 0 ? attributes : null);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const attributes = [
			{ key: 'Recipient Name', value: giftOptions.recipientName },
			{ key: 'Recipient Email', value: giftOptions.recipientEmail },
			{ key: 'Gift Note', value: giftOptions.giftNote },
			{ key: 'Delivery Date', value: giftOptions.deliveryDate },
		].filter((attr) => attr.value);

		onGiftOptionsChange(attributes.length > 0 ? attributes : null);
	};

	return (
		<div className="mt-4 mb-6 border-t border-b border-gray-200 py-4">
			<div className="mb-2">
				<CheckboxGroup
					label={<Typography variant="body">{giftConfig.copy.checkbox}</Typography>}
					name="is-gift"
					checked={isGift}
					onChange={handleCheckboxChange}
				/>
			</div>

			{showGiftForm && (
				<>
					<ul className="list-disc pl-5 mb-4 space-y-2">
						{giftConfig?.copy?.bullets?.map((bullet, index) => (
							<li key={index}>
								<Typography variant="body">{bullet}</Typography>
							</li>
						))}
					</ul>
					<form onSubmit={handleSubmit} className="mt-3 space-y-3">
						<div>
							<Typography
								variant="small"
								component="label"
								htmlFor="recipientName"
								className="block mb-1">
								Recipient Name
							</Typography>
							<input
								type="text"
								id="recipientName"
								name="recipientName"
								value={giftOptions.recipientName}
								onChange={handleInputChange}
								className="w-full p-2 border border-gray-300 rounded"
								placeholder="Enter recipient's name"
							/>
						</div>

						<div>
							<Typography
								variant="small"
								component="label"
								htmlFor="recipientEmail"
								className="block mb-1">
								Recipient Email
							</Typography>
							<input
								type="email"
								id="recipientEmail"
								name="recipientEmail"
								value={giftOptions.recipientEmail}
								onChange={handleInputChange}
								className="w-full p-2 border border-gray-300 rounded"
								placeholder="Enter recipient's email"
							/>
						</div>

						<div>
							<Typography
								variant="small"
								component="label"
								htmlFor="giftNote"
								className="block mb-1">
								Anything else we should tell them? (Optional)
							</Typography>
							<textarea
								id="giftNote"
								name="giftNote"
								value={giftOptions.giftNote}
								onChange={handleInputChange}
								className="w-full p-2 border border-gray-300 rounded"
								placeholder="Add a personal message"
								rows="3"
							/>
						</div>

						{giftConfig.date && (
							<div>
								<Typography
									variant="small"
									component="label"
									htmlFor="deliveryDate"
									className="block mb-1">
									Delivery Date
								</Typography>
								<input
									type="date"
									id="deliveryDate"
									name="deliveryDate"
									value={giftOptions.deliveryDate}
									onChange={handleInputChange}
									className="w-full p-2 border border-gray-300 rounded"
									min={new Date().toISOString().split('T')[0]}
								/>
							</div>
						)}
					</form>
				</>
			)}
		</div>
	);
};

export default GiftOptions;

GiftOptions.propTypes = {
	onGiftOptionsChange: PropTypes.func,
	product: PropTypes.object.isRequired,
};
