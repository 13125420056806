import Button from '/components/Button';
import Price from '/components/Price';
import PropTypes from 'prop-types';
import SubscriptionDesigns from '/components/products/subscription/standard/SubscriptionDesigns';
import Typography from '/components/Typography';
import { getCollectionProducts } from '/services/searchspring/';
import { logTryCatch } from '/utils/logging';

import {
	DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_MONTHLY_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_WOMENS_THONG_MONTHLY_DECIMAL,
} from '/services/static/default-prices';

import { useEffect, useState } from 'react';

export default function AlternateSubscriptionSelector({
	alternateSubscriptionCollection,
	cartType,
	flavor,
	setSubscriptionAlternateProduct,
	setModalSubscriptionOpen,
	size,
	subscriptionSettings,
}) {
	const [availableProducts, setAvailableProducts] = useState([]);
	const [customerSelections, setCustomerSelections] = useState({
		activeProduct: null,
		selectedFlavor: flavor,
		selectedProducts: [],
		selectedSize: '',
	});

	useEffect(() => {
		handleCustomerSelection({ selectedSize: size });

		if (!size) {
			return;
		}

		productRequest().catch((e) => logTryCatch(e));
	}, [size]);

	async function productRequest() {
		if (!alternateSubscriptionCollection) {
			return;
		}

		try {
			const response = await getCollectionProducts({
				isCollection: true,
				collectionHandle: alternateSubscriptionCollection,
				resultsPerPage: 20,
				page: 1,
				filters: {
					availableSizes: size,
				},
			});

			if (response?.results) {
				setAvailableProducts(response.results);
			} else {
				setAvailableProducts([]);
			}
		} catch (error) {
			console.error('Error fetching products:', error);
			setAvailableProducts([]);
		}
	}

	// updates the customer selection with an object of keys/values
	const handleCustomerSelection = (newValues) => {
		setCustomerSelections((previousValue) => {
			return {
				...previousValue,
				...newValues,
			};
		});
	};

	const toggleProduct = (product) => {
		handleCustomerSelection({ selectedProducts: [product] });
		setSubscriptionAlternateProduct(product);
	};

	const { selectedProducts } = customerSelections;

	// early exit - we don't have the data we need for rendering the picker
	if (cartType !== 'alternate-subscription') {
		return null;
	}

	//impossible to get these prices from selling plans before we load them on ATC
	const priceProductTypeMap = {
		'Boxers - Fly': DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_MONTHLY_DECIMAL,
		Thongs: DEFAULT_PRICE_SUBSCRIPTION_WOMENS_THONG_MONTHLY_DECIMAL,
	};

	return (
		<div className="my-4">
			<div className="p-2 m-1 mt-4 shadow-lg text-center bg-gradient-to-r from-[#008FF5]  to-[#45008C] text-white rounded-lg">
				<Typography component="p" variant="body">
					Get this pair + an extra pair of our classic MicroModal underwear. The subscription renews
					at{' '}
					<Price
						price={priceProductTypeMap[subscriptionSettings.alternateSubscriptionProductType]}
					/>
					/mo in our standard fabric.
					<Button
						className="capitalize !text-white underline underline-offset-2 hover:text-gray-100"
						onClick={() => setModalSubscriptionOpen(true)}
						title="How Membership Works"
						variant="link">
						How Membership Works
					</Button>
				</Typography>
			</div>
			{availableProducts.length > 0 && (
				<SubscriptionDesigns
					availableProducts={availableProducts}
					customerSelections={customerSelections}
					selectedProducts={selectedProducts}
					showOverlay={false}
					showSelectedItemTitle={true}
					textColor="black"
					title="Choose your additional pair"
					toggleProduct={toggleProduct}
				/>
			)}
		</div>
	);
}

AlternateSubscriptionSelector.propTypes = {
	alternateSubscriptionCollection: PropTypes.string,
	cartType: PropTypes.string.isRequired,
	flavor: PropTypes.string,
	setModalSubscriptionOpen: PropTypes.func.isRequired,
	setSubscriptionAlternateProduct: PropTypes.func.isRequired,
	size: PropTypes.string,
	subscriptionSettings: PropTypes.object.isRequired,
};
