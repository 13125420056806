import Clickable from '/components/Clickable';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { useGlobalStore } from '/state/global-context';

export default function ExtraFormInfo({ product }) {
	const globalStore = useGlobalStore();
	const promotion = globalStore.getPromotionalConfig();

	if (product.productType == 'Laundry Detergent Sheets') {
		return (
			<div className="flex items-center align-middle justify-center mt-4">
				<Image
					alt="Benefits of full of sheet laundry detergent strips"
					height={150}
					width={450}
					objectFit="contain"
					src="https://cdn.shinesty.com/2023-09-07/Full-of-Sheet-Benefits.png"
				/>
			</div>
		);
	}

	if (promotion && promotion.pdpBanner) {
		if (promotion.productTypes && !promotion.productTypes.includes(product.productType)) {
			return null;
		}

		return (
			<div className="rounded-lg">
				<Image
					alt="underwear hero"
					height={210}
					objectFit="contain"
					objectPosition="top"
					src={promotion.pdpBanner.imageSrcDesktop}
					width={600}
				/>
			</div>
		);
	}

	if (product.title.includes('The Family Jewels')) {
		return (
			<div className="bg-blue-900 rounded-md m-4 text-center text-white p-2">
				<Typography component="p" variant="body">
					20% of all sales go to the{' '}
				</Typography>
				<div>
					<Typography component="p" variant="body">
						Testicular Cancer Foundation.
					</Typography>
				</div>
			</div>
		);
	}

	if (product.title.includes('Breast Cancer Awareness')) {
		return (
			<div className="bg-v2-off-white flex m-4 rounded-md text-center p-2">
				<div className="mr-2 mt-[2px] shrink-0 self-center">
					<Image
						alt="Living beyond breast cancer's logo"
						height={36}
						width={41}
						src="https://cdn.shinesty.com/2022-10-03/lbbc-logo-mark%201.png"
					/>
				</div>

				<Typography component="p" variant="body">
					25% of all proceeds from every pair will be donated to{' '}
					<Clickable
						className="text-secondary-dark underline underline-offset-2"
						linkToExternal="https://www.lbbc.org/"
						target="_blank">
						Living Beyond Breast Cancer
					</Clickable>
					.
				</Typography>
			</div>
		);
	}

	return null;
}

ExtraFormInfo.propTypes = {
	product: PropTypes.object,
};
